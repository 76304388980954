import React from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Review from "../../../../Assets/images/review.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Heading from "../ui/Heading";
// import SlickLeft from "../Assets/img/left.png";
// import SlickRight from "../Assets/img/right.png";
import { isDesktop, isMobile } from "react-device-detect";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import googleImg from "../../../../Assets/images/unique/google-reviews.jpg"
import yelpImg from "../../../../Assets/images/unique/yelp-reviews.jpg"

const Customer = () => {
  const [customerData, setCustomerData] = React.useState([]);
  React.useEffect(() => {
    GetCustomerRewies();
    // console.log("customerdta", customerData);
  }, []);
  const GetCustomerRewies = () => {
    axios
      .get(`${base_url}/common/site_reviews`, {
        headers: postHeader,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          setCustomerData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <IoIosArrowRoundBack />,
    nextArrow: <IoIosArrowRoundForward />,
    // responsive: [
    //   {
    //     breakpoint: 1479,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 1,
    //       arrows: false,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 1025,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       arrows: false,
    //       dots: true,
    //     },
    //   },

    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       arrows: false,
    //       dots: true,
    //     },
    //   },
    // ],
  };
  const customerDataGoogle = [
    {
      name:"Lisa Savage",
      desc:"Unique Jewels is the only jeweler I would trust to inspect and repair my wedding ring, valuable, sentimental, or heirloom jewelry.",
      url:"https://www.google.com/search?q=uniquejewelshouston&oq=uniquejewelshouston&aqs=chrome..69i57j46j69i60l5.4184j0j7&sourceid=chrome&ie=UTF-8#lrd=0x86409c88a53d064d:0x5b6573be88d84f57,1,,,",
      reviewImg:googleImg,
    },
   
 
    {
      name:"Kathleen D.",
      desc:"I had such a lovely experience working with this jeweler. They helped me recreate a ring and even helped with some improvements. ",
      url:"https://www.yelp.com/biz/unique-jewels-webster",
      reviewImg:yelpImg,
    },
    {
      name:"Sara",
      desc:"Absolutely amazing service! Had a custom piece done that turned out perfect. ",
      url:"https://www.google.com/search?q=uniquejewelshouston&oq=uniquejewelshouston&aqs=chrome..69i57j46j69i60l5.4184j0j7&sourceid=chrome&ie=UTF-8#lrd=0x86409c88a53d064d:0x5b6573be88d84f57,1,,,",
      reviewImg:googleImg,
    },
    {
      name:"Alexandra R.",
      desc:"Unique Jewels is AMAZING. Kim was such a pleasure to work with, she brought my fiancé's engagement ring design to life and added a few more additional touches that I requested.",
      url:"https://www.yelp.com/biz/unique-jewels-webster",
      reviewImg:yelpImg,
    },
    {
      name:"Josh Posey",
      desc:"We have used Unique Jewels for restoration work with great success, so it was a no brainer to call Kim when I was wanting to get a set of custom earrings for my partner.",
      url:"https://www.google.com/search?q=uniquejewelshouston&oq=uniquejewelshouston&aqs=chrome..69i57j46j69i60l5.4184j0j7&sourceid=chrome&ie=UTF-8#lrd=0x86409c88a53d064d:0x5b6573be88d84f57,1,,,",
      reviewImg:googleImg,
    },
    {
      name:"Kim N.",
      desc:"Congratulations Alex and Francisco! You do have a very Unique engagement ring indeed and it was nice to see your personal touch to the design.",
      url:"https://www.yelp.com/biz/unique-jewels-webster",
      reviewImg:yelpImg,
    },
  
   
  ]
  return (
    <section className="venice_Customer">
      <h2 className='sectionTitle'>What People Say</h2>
      <div className="venice_Customer_inner">
        <Container className="venice-custome-container">

          {/* <Row> */}
          {/* <Col lg={12} data-aos="fade-up" data-aos-duration="1400"> */}
          <div className="venice_customer_review_slider">
            <Slider className="venice_customer_say_inner" {...settings2}>
              {customerDataGoogle.map((customdata) => (
                <div className="venice_customer_review_slide_item text-center">

                  <div className="venice_customer_slide_text_content">
                    <h3>{customdata?.name}</h3>
                    <p>
                      {customdata.desc.slice(0, 150)}
                      {customdata.desc.length > 150 ? "..." : ""}
                      <Link
                        to="#"
                        onClick={() =>
                          window.open(customdata.url, "_blank")
                        }
                      >
                        {" "}
                        Read More{" "}
                      </Link>
                    </p>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={customdata?.rating}
                      size="medium"
                      readOnly
                    />
                  </div>
                  <div className="venice_customer_slide_title_img mb-4">
                    <div className="venice_customer_review_slide_img m-auto d-table">
                      <LazyLoadImage
                        src={customdata.reviewImg}
                        alt="Google Review Image"
                        onClick={() =>
                          window.open(customdata.url, "_blank")
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* </Col> */}
          {/* </Row> */}

          {/* {isMobile && (
  <div className="venice_customer_review_btn text-center mt-5">
    <Button
      className="btn outline-base-btn large"
      onClick={() =>
        window.open(customerData?.google_review_url, "_blank")
      }
    >
      {" "}
      Write a Review{" "}
    </Button>
  </div>
)} */}
        </Container>
      </div>
    </section>
  );
};

export default Customer;
