import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { Container } from 'react-bootstrap';
import round from '../../../../Assets/images/venice/round.jpg'
import oval from '../../../../Assets/images/venice/oval.jpg'
import emerald from '../../../../Assets/images/venice/emerald.jpg'
import radiant from '../../../../Assets/images/venice/radiant.jpg'
import cushion from '../../../../Assets/images/venice/cushion.jpg'
import princess from '../../../../Assets/images/venice/princess.jpg'
import marquise from '../../../../Assets/images/venice/marquise.jpg'
import pear from '../../../../Assets/images/venice/pear.jpg'
import asscher from '../../../../Assets/images/venice/asscher.jpg'
import Heart from '../../../../Assets/images/venice/Heart.jpg'
import { Link } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
const DiamondShape = () => {
    return (
        <section className='venice_DiamondShape'>
            <h2 className='sectionTitle'>Shop Diamonds By Shape</h2>
            <Container fluid className='p-0'>
                <Swiper
                // breakpoints={{
                //     320: {
                //       width: 600,
                //       slidesPerView: 1,
                //     },
                //     768: {
                //       width: 768,
                //       slidesPerView: 2,
                //     },
                //     1000: {
                //       width: 1000,
                //       slidesPerView: 4,
                //     },
                //     1400: {
                //       width: 1400,
                //       slidesPerView: 6,
                //     },
                //     1900: {
                //       width: 1900,
                //       slidesPerView: 6,
                //     },
                //   }}
                    // pagination={{
                    //     clickable: true,
                    // }} 
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    effect={'coverflow'}
                    loop={true}
                    speed={600}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={isMobile ? 1 : isTablet ? 4 : 6}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }} 
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Round'><img alt='round' src={round} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Oval'><img alt='oval' src={oval} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Emerald'><img alt='emerald' src={emerald} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Radiant'><img alt='radiant' src={radiant} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Cushion'><img alt='cushion' src={cushion} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Princess'><img alt='princess' src={princess} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Marquise'><img alt='marquise' src={marquise} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Pear'><img alt='pear' src={pear} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Asscher'><img alt='asscher' src={asscher} /></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/diamonds/shape=Heart'><img alt='Heart' src={Heart} /></Link>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}

export default DiamondShape