import React from "react";
import { Container } from "react-bootstrap";

const Insta = () => {
  return (
    <section className="insta-section section-space">
      <h2 className='sectionTitle mb-2' >Get a Tour of our Social Media</h2>
      <p className="text-center">
        Follow us on Instagram{" "}
        <a
          href="https://www.instagram.com/uniquejewelshouston/"
          className="text-dark"
          target="_blank" rel="noreferrer"
        >
          <strong>@uniquejewelshouston</strong>
        </a>
      </p>
      <Container fluid>
        <iframe title='instapage' scrolling={false}
          src="https://instagram.demobw.live/uniquejewelshousten/"
          frameborder="0"
          width="100%"
          class="insta-iframe"
        ></iframe>
      </Container>
    </section>
  );
};

export default Insta;
