import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ring from '../../../../Assets/images/venice/engagement_col.jpg'
import necklace from '../../../../Assets/images/venice/necklace_col.jpg'
import wedding from '../../../../Assets/images/venice/wedding_rings_col.jpg'
import earrings from '../../../../Assets/images/venice/earrings_col.jpg'
import bracelet from '../../../../Assets/images/venice/bracelet_col.jpg'
const ExploreCollection = () => {
    const ExploreCollectiondata = [
        {
            word:'E',
            title: 'RINGS',
            subtitle:'Engagement Rings',
            des:'Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings.',
            img : ring,
            btntext:'Shop Now',
            btnurl:'/ringsettings',
        },
        {
            word:'N',
            title: 'NECKLACE',
            subtitle:'Necklaces',
            des:'Find the perfect length for every occasion with classic pearl strands, diamond elegance, colorful gemstones and more!',
            img : necklace,
            btntext:'Shop Now',
            btnurl:'/jewelry/necklaces',
        },
        {
            word:'W',
            title: 'RINGS',
            subtitle:'Wedding Rings',
            des:'Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique styles.',
            img : wedding,
            btntext:'Shop Now',
            btnurl:'/jewelry/wedding-rings',
        },
        {
            word:'E',
            title: 'EARRINGS',
            subtitle:'Earrings',
            des:'Look your best every day! Explore our exciting varieties of stylish earrings from short and sweet to long and dramatic.',
            img : earrings,
            btntext:'Shop Now',
            btnurl:'/jewelry/earrings',
        },
        {
            word:'B',
            title: 'BRACELETS',
            subtitle:'Bracelets',
            des:'Adorn your wrist with perfection and complement every style with feminine fashion, bangle, and diamond bracelets.',
            img : bracelet,
            btntext:'Shop Now',
            btnurl:'/jewelry/bracelets',
        },
    ]
    return (
        <section className='vanice-DiamondRing ExploreCollection'>
            <h2 className='sectionTitle'>Explore Our Collection</h2>
            {ExploreCollectiondata.map((data) => (
                <Container className='ExploreCollection_innerBox'>
                <Row>
                    <Col md={5}>
                        <div className='WordStyle'>{data.word}</div>
                        <div className='textBox'>
                            <h5 className='vanice-DiamondRing-subtitle'>{data.title}</h5>
                            <h2 className='vanice-DiamondRing-title'>{data.subtitle}</h2>
                            <p className='vanice-DiamondRing-text'>{data.des}</p>
                            <div className='vanice-DiamondRing-btn'>
                                <Link to={data.btnurl}>{data.btntext}</Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='ExploreCollectionImgBox'>
                        <Link to={data.btnurl} ><Image className='img-fluid' alt={data.subtitle} src={data.img} /></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            ))}
        </section>
    )
}

export default ExploreCollection